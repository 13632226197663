import { Menu } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, DropdownItem } from "reactstrap"
import { HasPermission } from "../helpers/HasPermission"


export const ActionButtons = ({actions, type, row, actionsDisabled=false, xs=false}) => {

    const [menuVisible, setMenuVisible] = useState(false)
    const [menuPosition, setMenuPosition] = useState({top:0, left:0})

    const ButtonType = () => (
        actions.map((action,key)=>(
            (!action.permission) || (HasPermission(action.permission)) ? (
                <React.Fragment  key={key}>
                    <Button disabled={actionsDisabled} title={action.label} color={action.color} className='btn-sm waves-effect waves-light' onClick={()=>action.fn({ row: row, ...action.params })}>
                        <i className={action.icon+" d-block font-size-12"}></i>
                    </Button>
                    {xs ? null : (
                        <>&nbsp;</>
                    )}
                </React.Fragment>
            ) : null
        ))
    )

    const MenuType = () => (
        <React.Fragment>
            <Button color={xs ? 'primary' : 'secondary'} disabled={actionsDisabled} className='btn-sm waves-effect waves-light' onClick={(e)=>{ const el = e.target.getBoundingClientRect();setMenuPosition({top: el.top+30, left:el.left});setMenuVisible(!menuVisible)} }>
                އެކްޝަންތައް{" "}<i className="mdi mdi-chevron-down"></i>
            </Button>
            <Menu disableAutoFocusItem anchorReference="anchorPosition" anchorPosition={menuPosition} keepMounted open={Boolean(menuVisible)} onClose={()=>setMenuVisible(false)}>
                {actions.map((action,key)=>(
                    (!action.permission) || (HasPermission(action.permission)) ? (
                        <DropdownItem toggle={false} onClick={()=>{
                            setMenuVisible(false);
                            setTimeout(() => {
                                action.fn({ row: row, ...action.params })
                            }, [10])
                        }} key={key}><span className={action.icon+" d-block font-size-14"}>{" "}{action.label}</span></DropdownItem>
                    ) : null
                ))}
            </Menu>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            {type === "buttons" ? <ButtonType /> : <MenuType />}
        </React.Fragment>
    )

}