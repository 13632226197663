import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
// import ForgotPassword from "../app/guest/ForgotPassword";
// import ChangePassword from "../app/guest/ChangePassword";
import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import Settings from "../app/auth/settings"
import CaseWorks from "../app/auth/caseWorks"
import CaseFiles from "../app/auth/caseFiles"
import GuestCaseFiles from "../app/auth/guestCaseFiles"
import RelationTypes from "../app/auth/relationTypes"
import ComplaintTypes from "../app/auth/complaintTypes"
import ArrestTypes from "../app/auth/arrestTypes"
// import WorkTypes from "../app/auth/workTypes"
import Locations from "../app/auth/locations"
import ArrestLocations from "../app/auth/arrestLocations";
import NoSidebarLayout from "../components/NoSidebarLayout";
import Atolls from "../app/auth/atolls";
import Islands from "../app/auth/islands";



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    // {
    //     name: 'Forgot Password',
    //     icon: 'bx bx-lock',
    //     link: '/forgot-password',
    //     component: ForgotPassword,
    //     layout: NonAuthLayout,
    //     middleware: 'guest',
    //     hidden: true,
    //     children: [],
    // },
    // {
    //     name: 'Change Password',
    //     icon: 'bx bx-lock',
    //     link: '/change-password',
    //     component: ChangePassword,
    //     layout: NonAuthLayout,
    //     middleware: 'guest',
    //     hidden: true,
    //     children: [],
    // },
    {
        name: 'ޑޭޝްބޯޑް',
        title: 'Dashboard',
        icon: 'mdi mdi-monitor-dashboard',
        link: '/home',
        defaultRoute: true,
        component: Home,
        middleware: 'auth',
        permission: 'read-caseFiles',
        children: [],
    },
	{ // Guest CaseFile Route Begin
        name: 'ކޭސް ފައިލް',
		title: 'Case File',
		icon: 'mdi mdi-file-document',
		link: '/home',
        defaultRoute: true,
		component: GuestCaseFiles,
		middleware: 'auth',
        layout: NoSidebarLayout,
		permission: 'guest-caseFiles',
		children: [],
	}, // CaseFile Route End
	{ // CaseFile Route Begin
        name: 'ކޭސް ފައިލް',
		title: 'Case File',
		icon: 'mdi mdi-file-document',
		link: '/caseFiles',
		component: CaseFiles,
		middleware: 'auth',
		permission: 'read-caseFiles',
		children: [],
	}, // CaseFile Route End
	{ // CaseWork Route Begin
		name: 'ކޭސް މަސައްކަތް',
		icon: 'mdi mdi-monitor-dashboard',
		link: '/caseWorks',
		component: CaseWorks,
		middleware: 'auth',
		permission: 'read-caseWorks',
		children: [],
        hidden: true,
        highlight: 'caseFiles'
	}, // CaseWork Route End
    {
        name: 'މާސްޓާ',
        icon: "bx bx-group",
        children: [
            { // Location Route Begin
                name: 'ތަން',
                title: 'Location',
                icon: 'mdi mdi-map-marker',
                link: '/locations',
                component: Locations,
                middleware: 'auth',
                permission: 'read-locations',
                children: [],
            }, // Location Route End
            { // Location Route Begin
                name: 'ބަންދު ތަން',
                title: 'Arrest Location',
                icon: 'mdi mdi-map-marker-radius',
                link: '/arrest-locations',
                component: ArrestLocations,
                middleware: 'auth',
                permission: 'read-arrestLocations',
                children: [],
            }, // Location Route End
            // { // WorkType Route Begin
            //     name: 'މަސައްކަތުގެ ބާވަތް',
            //     title: 'Work Type',
            //     icon: 'mdi mdi-briefcase',
            //     link: '/workTypes',
            //     component: WorkTypes,
            //     middleware: 'auth',
            //     permission: 'read-workTypes',
            //     children: [],
            // }, // WorkType Route End
            { // ComplaintType Route Begin
                name: 'ޝަކުވާގެ ބާވަތް',
                title: 'Complaint Type',
                icon: 'mdi mdi-alert-decagram',
                link: '/complaintTypes',
                component: ComplaintTypes,
                middleware: 'auth',
                permission: 'read-complaintTypes',
                children: [],
            }, // ComplaintType Route End
            { // ArrestType Route Begin
                name: 'ބަންދުގެ ބާވަތް',
                title: 'Arrest Type',
                icon: 'mdi mdi-account-details',
                link: '/arrestTypes',
                component: ArrestTypes,
                middleware: 'auth',
                permission: 'read-arrestTypes',
                children: [],
            }, // ArrestType Route End
            { // RelationType Route Begin
                name: 'ގުޅުމުގެ ބާވަތް',
                title: 'Relation Type',
                icon: 'mdi mdi-account-switch',
                link: '/relationTypes',
                component: RelationTypes,
                middleware: 'auth',
                permission: 'read-relationTypes',
                children: [],
            }, // RelationType Route End
            { // Atoll Route Begin
                name: 'އަތޮޅު',
                title: 'Atoll',
                icon: 'mdi mdi-map',
                link: '/atolls',
                component: Atolls,
                middleware: 'auth',
                permission: 'read-atolls',
                children: [],
            }, // Atoll Route End
            { // Island Route Begin
                name: 'ރަށް',
                title: 'Island',
                icon: 'mdi mdi-island',
                link: '/islands',
                component: Islands,
                middleware: 'auth',
                permission: 'read-islands',
                children: [],
            }, // Island Route End
        ]
    },
	// Navigations
    
    {
        name: 'އެކްސެސް ކޮންޓްރޯލް',
        icon: "bx bx-group",
        children: [
            {
                name: 'ޔޫސާތައް',
                title: 'Users',
                icon: 'bx bx-group',
                link: '/acl/users',
                component: Users,
                middleware: 'auth',
                permission: 'read-users',
                children: []
            },
            {
                name: 'ރޯލްތައް',
                title: 'Roles',
                icon: 'mdi mdi-account-key-outline',
                link: '/acl/roles',
                component: Roles,
                middleware: 'auth',
                permission: 'read-roles',
                children: []
            },
            {
                name: 'ހުއްދަތައް',
                title: 'Permissions',
                icon: 'bx bx-lock-open-alt',
                link: '/acl/permissions',
                component: Permissions,
                middleware: 'auth',
                permission: 'read-permissions',
                children: []
            },
        ]
    },
    
    {
        name: 'އެހެނިހެން',
        icon: "bx bx-group",
        children: [
            { // Setting Route Begin
                name: 'ސެޓިންގްސް',
                title: 'Setting',
                icon: 'mdi mdi-cogs',
                link: '/settings',
                component: Settings,
                middleware: 'auth',
                permission: 'read-settings',
                children: [],
            }, // Setting Route End
        ]
    }
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
            let returnVal = null
            if(nav.children.length > 0){
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest" )) || !nav.middleware){
                    let tempChildren = []
                    nav.children.forEach((child)=>{
                        let temp = parseModule(child)
                        if(temp){
                            tempChildren = [...tempChildren, temp]
                        }
                    })
                    returnVal = {...nav, children: tempChildren}
                }
            } else {
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest")) || !nav.middleware){
                    returnVal = nav
                }
            }
            return returnVal
        }
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
