
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import { getDataListRequest } from "../../api/controller/DashboardController";
import ReactApexChart from "react-apexcharts";

const Home = () => {

    useEffect(() => {
        async function fetchData(){
            const response = await getDataListRequest()
            if(response.ArrestType){
                setArrestTypeData({
                    data: response.ArrestType.map((item) => item.count),
                    labels: response.ArrestType.map((item) => item.dashname),
                })
            }
            if(response.Status){
                setStatusData({
                    data: response.Status.map((item) => item.count),
                    labels: response.Status.map((item) => item.dashname),
                })
            }
            if(response.ComplaintType){
                setComplaintTypeData({
                    data: response.ComplaintType.map((item) => item.count),
                    labels: response.ComplaintType.map((item) => item.dashname),
                })
            }
        }
        fetchData()
    }, [])

    const [arrestTypeData, setArrestTypeData] = useState({
        data: [
            // 380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365
        ],
        labels: [
            // "ސައުތް ކޮރެއާ","Canada","United Kingdom","Netherlands","Italy","France","Japan","United States","China","Germany",
        ]
    })

    const [statusData, setStatusData] = useState({
        data: [
            // 380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365
        ],
        labels: [
            // "ސައުތް ކޮރެއާ","Canada","United Kingdom","Netherlands","Italy","France","Japan","United States","China","Germany",
        ]
    })

    const [complaintTypeData, setComplaintTypeData] = useState({
        data: [
            // 380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365
        ],
        labels: [
            // "ސައުތް ކޮރެއާ","Canada","United Kingdom","Netherlands","Italy","France","Japan","United States","China","Germany",
        ]
    })

    const arrestTypeChart = useMemo(() => ({
        series: arrestTypeData.data || [],
        options: {
            labels: arrestTypeData.labels || [],
            colors: ["#15428a","#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c","#4c1375",],
            legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            floating: false,
            fontSize: "14px",
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        }
    }), [arrestTypeData])

    const statusChart = useMemo(() => ({
        series: [
            {
                data: statusData.data || [],
            }
        ],
        options: {
            chart: {
                toolbar: {
                  show: false,
                },
            },
            plotOptions: {
            bar: {
                horizontal: true,
            },
            },
            dataLabels: {
            enabled: false,
            },
        
            colors: ["#34c38f"],
            grid: {
            borderColor: "#f1f1f1",
            },
            yaxis: {
                floating: false,
                labels: {
                    style: {
                        fontSize: '18px',
                    },
                    maxWidth: 350
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                    }
                },
                categories: statusData.labels || [],
                floating: false,
            },
        }
    }), [statusData])

    const complaintTypeChart = useMemo(() => ({
        series: [
            {
                data: complaintTypeData.data || [],
            }
        ],
        options: {
            chart: {
                toolbar: {
                  show: false,
                },
            },
            plotOptions: {
            bar: {
                horizontal: false,
            },
            },
            dataLabels: {
            enabled: false,
            },
        
            colors: ["#7a1b38"],
            grid: {
            borderColor: "#f1f1f1",
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                    }
                }
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                    }
                },
                categories: complaintTypeData.labels || [],
            },
        }
    }), [complaintTypeData])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h3>ޑޭޝްބޯޑް</h3>
                    <Row>
                        <Col xs={12} md={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 dvxl">ބަންދުގެ ބާވަތްތައް</CardTitle>
                                    <div dir="ltr" className="dvchart">
                                        <ReactApexChart options={arrestTypeChart.options} series={arrestTypeChart.series} type="pie" height="400" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12} md={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 dvxl">ސްޓޭޓްސްތައް</CardTitle>
                                    <div dir="ltr" className="dvchart">
                                        <ReactApexChart options={statusChart.options} series={statusChart.series} type="bar" height="350" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 dvxl">ޝަކުވާގެ ބާވަތް</CardTitle>
                                    <div dir="ltr" className="dvchart">
                                        <ReactApexChart options={complaintTypeChart.options} series={complaintTypeChart.series} type="bar" height="350" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Home;

