import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getAtollListRequest } from "../../../api/controller/IslandController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const islandListState = atom({
    key: 'islandListState',
    default: [],
})

export const islandAtollState = atom({
	key: 'islandAtollState',
	default:{
		data:[],
		ovf:false,
	}
})


export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const islandDataStructureState = selector({
    key: 'islandDataStructureState',
    get: ({ get }) => (
        [
            
			{ 
                label: 'އަތޮޅު',
                name: 'Atoll',
                field: 'atoll',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(islandAtollState),
				getRequest: getAtollListRequest,
            },
            { 
                label: 'ރަށް',
                name: 'Name',
                field: 'name',
                type: 'text',
                order: { table: 2, form: 2 }, 
                table: { dv: true },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
