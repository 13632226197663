import React, { useEffect, useState } from 'react';

import { Alert, Col, Container, Row } from "reactstrap";
import { withRouter } from 'react-router-dom';
// import { AvField, AvForm } from 'availity-reactstrap-validation';

// import images
// import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { loginPageImageState } from '../../api/state/AuthState';
// import { Loader } from '../../custom/components/Loader';

import efaasWhiteIcon from "../../assets/images/efaaswhiteicon.svg";
import { useLocation } from 'react-router-dom';

 

const CarouselPage = () => {

    const loginPageImage = useRecoilValue(loginPageImageState)
    
    const imagePath = process.env.REACT_APP_API_PUBLIC_URL + loginPageImage.image;

    return (
      <React.Fragment>
        <Col xl={9} style={{ backgroundColor:'#000'}}>
          <div className="auth-full-bg">
            <div className="w-100" style={{position:'relative'}}>
              <div style={{background: 'url("'+imagePath+'") center center / cover no-repeat', height: "100%"}}></div>
              {loginPageImage.description && loginPageImage.description !== 'null' ? (
                <h1 align="center" style={{position:"absolute",bottom:"20%", width:"100%", backgroundColor:"rgba(0,0,0,0.4)", padding:"0.8em", fontFamily:'faruma', direction: 'rtl'}}>{loginPageImage.description}</h1>
              ) : null}
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }

const Login = ({history,handleAuthResponse}) =>  {
    const [code, setCode] = useState(null)
    const [error, setError] = useRecoilState(loginErrorState)

    const location = useLocation()

    useEffect(() => {
        const code = new URLSearchParams(location.search).get("code")
        const error = new URLSearchParams(location.search).get("error")
        if(code || error){
            history.push('/login')
        }
        if(code){
        setCode(code)
        if(error){
            setError(error)
        }
        }
    }, [location, setError, setCode, history])

    return (
        <React.Fragment>
            <Container fluid className="p-0" style={{overflow:'hidden'}}>
                <Row className="no-gutters">
                    <CarouselPage  /> 
                    <Col xl={3}>
                    <div className="auth-full-page-content p-md-5 p-4">
                        <div className="w-100">
                        <div className="d-flex flex-column h-100">
                            <div className="my-auto mt-5">
                            <div align="center" className="mb-4 mb-md-5">
                                <img
                                src={logo}
                                alt=""
                                height="150"
                                style={{justifyContent:'center', aligneItem:'center'}} 
                                />
                                <h1 className='mt-4'>މަހައްލު ޕޯޓަލް</h1>
                            </div>
                            <hr />
                            
                            {error && <Alert className="dvl" color="danger">{error}</Alert>}
                            <div align="center">
                                {code && parseInt(code) === 1 ? (
                                    <button className='btn btn-info' onClick={()=>{
                                        window.location.href = (process.env.REACT_APP_API_PUBLIC_URL + "/auth/efaas/consentRedirect")
                                    }}><span style={{fontSize:"2em", padding: "0 2em"}}>ހުއްދަ ދެއްވާ</span></button>
                                ) : <>
                                    <h4 align="center" className='mb-4 mt-4'>އީފާސް ބޭނުން ކޮށްގެން ލޮގިންވެލާ</h4>
                                    {/* <Button className='btn btn-info' style={{padding: "2em", borderRadius:"15px", cursor: 'pointer'}} onClick={() => {
                                            window.location.href = (process.env.REACT_APP_API_PUBLIC_URL + "/auth/efaas/loginRedirect")
                                        }}>
                                        <img src={efaasLogo} alt="efaas login" width="100%" style={{justifyContent:'center', aligneItem:'center'}} />
                                    </Button> */}
                                    <button className='efaas-container' dir="ltr" onClick={() => {
                                        window.location.href = (process.env.REACT_APP_API_PUBLIC_URL + "/auth/efaas/loginRedirect")
                                    }}>
                                        <img src={efaasWhiteIcon} alt="efaas login" height="28px" style={{marginRight: '5px'}} />Sign in with eFaas
                                    </button>
                                </>}
                                <hr className='mt-5' />
                                <h5 className='mb-0 mt-5' style={{color:'#2191d5', cursor: 'pointer'}} onClick={() => {
                                    window.location.href = 'https://efaas.gov.mv/Account/Verify'
                                }}>އީފާސް އެކައުންޓެއް ހައްދަވާ</h5>
                            </div>
                                 
                                  
                            </div>
                            <div className="mt-4 mt-md-5 text-center">
                                <p className="mb-0 mt-5">Copyright © {new Date().getFullYear()}, Ministry of Home Affairs{""}<br/> </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                </Row>
                </Container>
        </React.Fragment>
    );
}

export default withRouter(Login);
