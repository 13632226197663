import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Modal, Row, UncontrolledTooltip } from "reactstrap"
import { Loader } from '../../../custom/components/Loader';
// import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import moment from 'moment';
import { map } from 'lodash';
import { markAsReadCaseChatsRequest, messageCaseChatsRequest } from '../../../api/controller/CaseFileController';
import { v4 as uuidv4 } from 'uuid';
import { isDhivehi } from '../../../custom/helpers/IsDhivehi';
import echo from '../../../api/core/echo';
import { useRecoilValue } from 'recoil';
import { permissionState } from '../../../api/state/AuthState';
import notify from '../../../custom/helpers/Notify';


export const ChatModal = ({visible, loading, data, toggle}) => {

    const [readTimer, setReadTimer] = useState(null);
    const scrollDivRef = useRef(null);
    const fileRef = useRef(null)
    const [currentMessage, setCurrentMessage] = useState("");
    const [messages, _setMessages] = useState([]);
    const messagesRef = useRef(messages)
    const permissions = useRecoilValue(permissionState)
    const setMessages = data => {
        messagesRef.current = data;
        _setMessages(data)
    }
    useEffect(() => {
        setMessages(data?.Messages || [])
    }, [data.Messages])

    useEffect(() => {
        if(scrollDivRef.current){
            setTimeout(() => {
                scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
            }, [50])
        }
    }, [messages])

    const [channel, setChannel] = useState(null);



    useEffect(() => {
        if(visible && data.id){
            if(readTimer){
                clearTimeout(readTimer);
            }
            setReadTimer(setInterval(() => {
                markAsReadCaseChatsRequest({ CaseFile: data.id });
            }, [5000]))
            setChannel(`CaseChat-Primary.${data.id}`)
            echo.private(`CaseChat-Primary.${data.id}`).listen(`.CaseChatEvents`, (sd) => {
                if(echo.socketId() !== sd.from && sd.event === "Created"){
                    console.log(sd, data, permissions.includes("read-caseFiles"))
                    const isMine = permissions.includes("read-caseFiles") ? sd.data.user_id !== data.Rep : sd.data.user_id === data.Rep;
                    setMessages([...messagesRef.current, {...sd.data, user_name: permissions.includes("read-caseFiles") || isMine ? sd.data.user_name : "I.C.S.", is_mine: isMine}]);
                }
            });
        } else if(readTimer) {
            clearTimeout(readTimer);
        }
        if(! visible && channel){
            echo.leave(channel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, data.id])
    

    const sendMessage = async(msg, attch) => {
        if(msg?.length > 0 || attch){
            if(msg?.length > 0){
                setCurrentMessage("");
            }
            const uniqueId = uuidv4();
            setMessages([...messages, {
                id: uniqueId,
                case_file_id : data.id,
                description : msg,
                is_mine : true,
                user_name : 'You',
                sent_time : moment().format("YYYY-MM-DD HH:mm"),
                attachment : attch,
                delivered: false,
            }])
            const response = await messageCaseChatsRequest({ CaseFile: data.id, Message: msg, Attachment: attch });
            if(response.Status !== "success"){
                setMessages(messagesRef.current.map(message => message.id === uniqueId ? {...message, failed: true} : message))
                console.log(response)
                if(response?.Code && response.Code === "101"){
                    notify({type: "toastr", status: "error", title:"", message: response.Message})
                }
            } else {
                setMessages(messagesRef.current.map(message => message.id === uniqueId ? response.Data : message))
            }
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    return (
        <Modal size="lg" isOpen={visible} toggle={()=>toggle({})} keyboard={false} backdrop="static">
            <div className="modal-header">
                <h5 className="modal-title mt-0">ކޭސް ޗެޓް</h5>
                <button type="button" onClick={() => toggle({})} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <React.Fragment>
                <div id="modalBody" className="modal-body" style={{fontSize: 'larger', position: 'relative'}}>
                    <Loader loading={loading} />
                    <div className="w-100 user-chat">
                        <Card style={{marginBottom:0}}>
                            <div className="p-2 border-bottom ">
                                <Row>
                                    <Col xs={12}>
                                        <h2 className="font-size-24 mb-1" align="left"><strong>{data.Case}</strong></h2>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row className="chat-conversation p-3">
                                    <ul className="list-unstyled">
                                        <div style={{ height: "470px", overflowY:'scroll' }} ref={scrollDivRef}>
                                            {/* <li><div className="chat-day-title"><span className="title">Today</span></div></li> */}
                                            {messages && map(messages, message => (
                                                <li key={"test_k" + message.id} className={message.is_mine ? "" : "right"} style={message.attachment ? { cursor: 'pointer' } : {} }  
                                                    onClick={() => message.attachment && window.open(process.env.REACT_APP_API_PUBLIC_URL + message.attachment, '_blank')}
                                                >
                                                    <div className="conversation-list">
                                                        <div className={(message.failed ? "ctext-wrap failed-message" : "ctext-wrap")}>
                                                            <div className={isDhivehi(message.user_name) ? "conversation-name" : "conversation-name en"}>
                                                                {message.user_name}
                                                            </div>
                                                            <p className={(message.attachment || isDhivehi(message.description)) ? "" : "en"} align={(message.attachment || isDhivehi(message.description)) ? "right" : "left"}>
                                                                {message.attachment && message.delivered ? <><span>ޑައުންލޯޑް </span><i className="mdi mdi-download font-size-24 align-middle me-1" /></> : message.description}
                                                            </p>
                                                            <p className={"chat-time mb-0" + (message.failed ? "" : " en")}>
                                                                {!message.failed ? (
                                                                    <>
                                                                        {message.delivered ? 
                                                                            <i className="mdi mdi-check align-middle me-1" /> : 
                                                                                <i className="mdi mdi-clock-time-four-outline align-middle me-1" />}
                                                                        {" "}{moment(message.sent_time).format("DD-MMM | hh:mm")}
                                                                    </>
                                                                ) : (
                                                                    <><i className="mdi mdi-alert-box-outline align-middle me-1" />{message?.Message || " ޑެލިވާ ނުކުރެވުނު"}</>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </div>
                                    </ul>
                                </Row>
                                <div className="p-3 chat-input-section">
                                    <Row>
                                        <Col>
                                            <div className="position-relative">
                                            <input type="text" disabled={!data.Chattable} value={currentMessage} className="form-control chat-input dv" placeholder={data.Chattable ? "މެސެޖް ލިޔުއްވާ..." : " ޗެޓް ޑިސްއޭބަލް ކުރެވިފައި..."}
                                                onKeyUp={(event) => event.keyCode === 13 ? sendMessage(currentMessage) : null}
                                                onChange={e => setCurrentMessage(e.target.value)}    
                                            />
                                            <input type="file" disabled={!data.Chattable} ref={fileRef} style={{opacity:0,position:'absolute'}} onChange={async(e) => {
                                                if(e.target.files.length > 0){
                                                    const file = await toBase64(e.target.files[0]);
                                                    sendMessage(null, file)
                                                    e.target.value = null;
                                                }
                                            }} />
                                            <div className="chat-input-links">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <Link style={{opacity: data.Chattable ? 1 : 0.5}} to="#" onClick={() => fileRef.current && fileRef.current.click()}>
                                                            <i className="mdi mdi-file-document-outline" id="Filetooltip" />
                                                            <UncontrolledTooltip placement="top" target="Filetooltip">Add Files</UncontrolledTooltip>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            </div>
                                        </Col>
                                        <Col className="col-auto">
                                            <Button disabled={!data.Chattable} type="button" color="primary" className="btn btn-primary btn-rounded chat-send w-md dv" onClick={() => sendMessage(currentMessage)}>
                                                <span className="d-none d-sm-inline-block me-2">ފޮނުވާ</span>{" "}
                                                <i className="mdi mdi-send flip-icon" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" onClick={() => toggle({})} className="btn btn-secondary waves-effect" data-dismiss="modal">ކްލޯސް</button>
                </div>
            </React.Fragment>
        </Modal>
    )

}