
import http from "../core/http";

const group = 'auth';

export const loginRequest =  async (data) => {
    return await http.post(`${group}/login`, data).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}
export const verifyLoginRequest =  async (data) => {
    return await http.post(`${group}/verify`, data).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}

export const checkAuthRequest =  async () => {
    return await http.get(`${group}/me`).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}

export const tokenRefreshRequest =  async () => {
    return await http.get(`${group}/refresh`).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}

export const logoutRequest =  async () => {
    return await http.post(`${group}/logout`).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}


export const getMetaData =  async (data) => {
    return await http.post(`${group}/getMetaData`, data).then(res => res.data).catch(http.defaultCatch);
}

export const changePasswordRequest =  async (data) => {
    return await http.post(`${group}/password/change`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateLoginCoverRequest =  async (data) => {
    return await http.post(`${group}/updateLoginCover`, data).then(res => res.data).catch(http.defaultCatch);
}

export const initPasswordReset =  async (data) => {
    return await http.post(`${group}/password/resetInit`, data).then(res => res.data).catch(http.defaultCatch);
}

export const validatePasswordReset =  async (data) => {
    return await http.post(`${group}/password/resetValidate`, data).then(res => res.data).catch(http.defaultCatch);
}

export const completePasswordReset =  async (data) => {
    return await http.post(`${group}/password/resetComplete`, data).then(res => res.data).catch(http.defaultCatch);
}