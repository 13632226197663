import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/SettingController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const settingListState = atom({
    key: 'settingListState',
    default: [],
})


export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const settingDataStructureState = selector({
    key: 'settingDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'ނަން',
                name: 'Key',
                field: 'key',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'readOnly'  }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ވެލިއު',
                name: 'Value',
                field: 'value',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
