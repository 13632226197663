import http from "../core/http";
const group = 'caseWorks';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCaseFileListRequest =  async (data) => {
	return await http.post(`${group}/getCaseFileList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getWorkTypeListRequest =  async (data) => {
	return await http.post(`${group}/getWorkTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getSentLocationListRequest =  async (data) => {
	return await http.post(`${group}/getSentLocationList`, data).then(res => res.data).catch(http.defaultCatch);
}
