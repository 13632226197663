import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getCaseFileListRequest, getWorkTypeListRequest, getSentLocationListRequest } from "../../../api/controller/CaseWorkController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import { Badge } from "reactstrap";

export const caseWorkListState = atom({
    key: 'caseWorkListState',
    default: [],
})
export const caseWorkCaseFileState = atom({
	key: 'caseWorkCaseFileState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseWorkWorkTypeState = atom({
	key: 'caseWorkWorkTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseWorkSentLocationState = atom({
	key: 'caseWorkSentLocationState',
	default:{
		data:[],
		ovf:false,
	}
})
export const caseWorkActiveCaseFileState = atom({
    key: 'caseWorkActiveCaseFileState',
    default: null,
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const caseWorkDataStructureState = selector({
    key: 'caseWorkDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'ކޭސް ފައިލް',
                name: 'CaseFile',
                field: 'case_file',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', preventClear: true }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(caseWorkCaseFileState),
				getRequest: getCaseFileListRequest,
            },
			{ 
                label: 'މަސައްކަތުގެ ބާވަތް',
                name: 'WorkType',
                field: 'work_type',
                type: 'select',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(caseWorkWorkTypeState),
				getRequest: getWorkTypeListRequest,
            },
			{ 
                label: 'ފޮނުވުނު ތަން',
                name: 'SentLocation',
                field: 'sent_location',
                type: 'select',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'hidden_if:WorkType,ޖަވާބު ސިޓީ'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(caseWorkSentLocationState),
				getRequest: getSentLocationListRequest,
            },
			{ 
                label: 'ނަންބަރު',
                name: 'Number',
                field: 'number',
                type: 'text',
                order: { table: 4, form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'hidden_if:WorkType,ރަސްމީ ބައްދަލުވުން ބޭއްވުން|hidden_if:WorkType,ޖަވާބު ސިޓީ'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
                labelFn: (fd) => fd.WorkType === 1 ? "ފޯމް ނަންބަރު" : "ސިޓީ ނަންބަރު"
            },
			{ 
                label: 'އެޓޭޗްމެންޓްތައް',
                name: 'WorkAttachments',
                field: 'work_attachments',
                type: 'repeater',
                order: { form: 5 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, validations: 'hidden_if:WorkType,ރަސްމީ ބައްދަލުވުން ބޭއްވުން|hidden_if:WorkType,ޖަވާބު ސިޓީ' }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'ނަން',
                        name: 'Name',
                        field: 'name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'ފައިލް',
                        name: 'File',
                        field: 'file',
                		type: 'file',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'ބައްދަލުކުރެވުނު ފަރާތް',
                name: 'PersonsMet',
                field: 'persons_met',
                type: 'text',
                order: { form: 6 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv', validations: 'hidden_if:WorkType,!ރަސްމީ ބައްދަލުވުން ބޭއްވުން'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ނިންމުނު ކަންކަން',
                name: 'DecisionsTaken',
                field: 'decisions_taken',
                type: 'textarea',
                order: { form: 7 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '700', rows: '3' }, classes: 'dv', validations: 'hidden_if:WorkType,!ރަސްމީ ބައްދަލުވުން ބޭއްވުން' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ސުންގަޑި',
                name: 'Deadline',
                field: 'deadline',
                type: 'datepicker',
                order: { table: 5, form: 8 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD', customRender: (value, params) => (
                    <Badge color={params.data["deadline_color"]} className={"bg-"+(params.data["deadline_color"])+" font-size-14"} style={{padding: "5px 10px"}}>
                        {value || "-"}
                    </Badge>
                ) },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' }, validations: 'hidden_if:WorkType,ރަސްމީ ބައްދަލުވުން ބޭއްވުން|hidden_if:WorkType,ޖަވާބު ސިޓީ' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'އިތުރު ފިޔަވަޅު',
                name: 'AdditionalActions',
                field: 'additional_actions',
                type: 'textarea',
                order: { form: 9 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '700', rows: '3' }, classes: 'dv', validations: 'hidden_if:WorkType,ޖަވާބު ސިޓީ' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'އިތުރު ބަޔާން',
                name: 'AdditionalInformation',
                field: 'additional_information',
                type: 'textarea',
                order: { form: 10 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '700', rows: '3' }, classes: 'dv', validations: 'hidden_if:WorkType,ރަސްމީ ބައްދަލުވުން ބޭއްވުން|hidden_if:WorkType,ޖަވާބު ސިޓީ' }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޖަވާބު އެޓޭޗްމެންޓްތައް',
                name: 'ResponseAttachments',
                field: 'response_attachments',
                type: 'repeater',
                order: { form: 11 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false, validations: 'hidden_if:WorkType,ރަސްމީ ބައްދަލުވުން ބޭއްވުން|hidden_if:WorkType,ޖަވާބު ސިޓީ' }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'ނަން',
                        name: 'Name',
                        field: 'name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'ފައިލް',
                        name: 'File',
                        field: 'file',
                		type: 'file',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'ޖަވާބު ސިޓީ',
                name: 'AnswerLetter',
                field: 'answer_letter',
                type: 'file',
                order: { form: 12 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }, validations: 'hidden_if:WorkType,!ޖަވާބު ސިޓީ'  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'މަސައްކަތް ނިމުނު ތާރީޚް',
                name: 'CompletedOn',
                field: 'completed_on',
                type: 'datepicker',
                order: { table: 6, form: 13 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD', customRender: (value, params) => (
                    <Badge color={value ? 'primary' : 'info'} className={"bg-"+(value ? 'primary' : 'info')+" font-size-14"} style={{padding: "5px 10px"}}>
                        {value || "-"}
                    </Badge>
                ) },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
