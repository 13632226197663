import React, { useEffect, useState, useMemo, useRef } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import "./assets/scss/theme.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { authState, permissionState, userState, tokenState, tokenRefreshIntervalState, loginPageImageState } from './api/state/AuthState';
import { megaMenuState, navigationState } from './state/NavigationState';
import { checkAuthRequest, getMetaData, tokenRefreshRequest } from "./api/controller/AuthController";
import { defaultAuthLayoutState, defaultGuestLayoutState, preloaderState, tokenNeedsRefreshState, themeTypeState } from "./state/GlobalState";
import moment from "moment";
import Pages404 from "./app/404";
import echo from './api/core/echo'

const App = () => {

  const [isAuth, setAuth] = useRecoilState(authState)
  const resetAuth = useResetRecoilState(authState)
  const setUser = useSetRecoilState(userState)
  const resetUser = useResetRecoilState(userState)
  const [token, setToken] = useRecoilState(tokenState)
  const resetToken = useResetRecoilState(tokenState)
  const setPermissions = useSetRecoilState(permissionState)
  const resetPermissions = useResetRecoilState(permissionState)
  const navigation = useRecoilValue(navigationState)
  const megaMenu = useRecoilValue(megaMenuState)
  const [preloader, setPreloader] = useRecoilState(preloaderState)
  const [tokenNeedsRefresh, setTokenNeedsRefresh] = useRecoilState(tokenNeedsRefreshState)
  const tokenRefreshInterval = useRecoilValue(tokenRefreshIntervalState)
  const [expiryTimer, setExpiryTimer] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const DefaultAuthLayout = useRecoilValue(defaultAuthLayoutState)
  const DefaultGuestLayout = useRecoilValue(defaultGuestLayoutState)
  const defaultRoute = useRef(null)
  const themeType = useRecoilValue(themeTypeState)
  const setLoginImage = useSetRecoilState(loginPageImageState)

  const resetAuthStates = useMemo(() => () => {
    resetUser()
    resetToken()
    resetPermissions()
    resetAuth()
  }, [resetUser, resetAuth, resetPermissions, resetToken])

  useEffect(() => {
    async function getMeta(){
      const response = await getMetaData()
      if(response && response.loginImage){
        localStorage.setItem('loginImage', response.loginImage.file)
        localStorage.setItem('loginDesc', response.loginImage.description)
        setLoginImage({image: response.loginImage.file, description: response.loginImage.description})
      }
    }
    getMeta()
  }, [setLoginImage])

  const setEchoToken = (token) => {
    if(process.env.REACT_APP_WEBSOCKETS_ENABLED === 'true' && echo.options){
      if(echo.options.auth){
        echo.options.auth.headers['Authorization'] = `Bearer ${token}`
      } else {
        let auth = { headers: { Authorization: `Bearer ${token}`, Accept: 'application/json', } }
        echo.options = { ...echo.options, auth: auth }
      }
    }
  }

  useEffect(() => {
    setEchoToken(token.token)
  }, [token.token])

  const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    let temp = ''
    name.trim().split(" ").forEach((v)=>{temp+=v[0]})
    name = temp.toUpperCase().substr(0,2)
    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    canvas.width=canvas.height=size

    context.fillStyle="#ffffff"
    context.fillRect(0,0,size,size)

    context.fillStyle=`${color}50`
    context.fillRect(0,0,size,size)

    context.fillStyle=color;
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`${size/2}px Roboto`
    context.fillText(name,(size/2),(size/2))

    return canvas.toDataURL()
  };

  const handleAuthResponse = useMemo(() => (response, token=null, rememberMe=false, login=false) => {
    if(response.decodedToken){
      const tokenExpiry = moment(response.decodedToken.created_at).add(tokenRefreshInterval, 'minutes');
      if(tokenExpiry.isAfter(moment())){
        const user = {...response.user, avatar: response.user.avatar || createImageFromInitials(120, response.user.name, "#556ee6")}
        setUser(user)
        setAuth(true)
        setPermissions(response.decodedToken.abilities || [])
        if(login){
          setEchoToken(token)
          history.push("/home")
        }
        setToken({token: token, expiry: tokenExpiry})
        setTokenNeedsRefresh(false)
        const ttl = tokenExpiry.diff(moment())
        clearTimeout(expiryTimer)
        setExpiryTimer(setTimeout(()=>{
          setTokenNeedsRefresh(true)
        }, ttl))
        localStorage.setItem('authToken', token)
        if(localStorage.getItem('authUser') || rememberMe){
          localStorage.setItem('authUser', JSON.stringify(user))
        }
      } else {
        localStorage.removeItem('authToken')
      }
    } else {
      resetAuthStates()
      localStorage.removeItem('authToken')
      history.push('/');
    }
    // eslint-disable-next-line
  }, [setUser, setAuth, setPermissions, setToken, setExpiryTimer, setTokenNeedsRefresh, expiryTimer, resetAuthStates, tokenRefreshInterval]);


  useEffect(() => {
    let token = new URLSearchParams(location.search).get("token")
    if(token){
      localStorage.setItem('authToken', token)
      history?.replace(/#.*$/, '')?.replace(/\?.*$/, '')
    }
    if(! token){
      token = localStorage.getItem('authToken')
    }
  
    async function checkAuth(){
      if(token && !isAuth){
        const response = await checkAuthRequest()
        handleAuthResponse(response, token)
      }
      setPreloader(false)
    }
    checkAuth()
  }, [handleAuthResponse, isAuth, setPreloader, history, location.search])
  
  useEffect(() => {
    if(tokenNeedsRefresh) {
      async function refreshToken(){
        const response = await tokenRefreshRequest()
        handleAuthResponse(response, response.encodedToken)
      }
      refreshToken();
    }
  }, [tokenNeedsRefresh, handleAuthResponse])

  const renderRoute = ({route, index}) => {
    const props = route.link === "/login" ? {handleAuthResponse: handleAuthResponse} : null;
    const Layout = route.layout ? route.layout : route.middleware === "guest" ? DefaultGuestLayout : DefaultAuthLayout
    const Component = route.component
    const routeProps = route.link === "/" ? {exact:true} : null
    if(route.defaultRoute){
      defaultRoute.current = route.link
    }
    return (
      <Route key={index} {...routeProps} path={route.link}>
        <Layout><Component {...props} /></Layout>
      </Route>
    )
  }

  const parseItem = (item, idx) => (
    item.children.filter(c=>!c.hidden).length > 0 ? (
      item.children.map((child,childIdx)=>(
        parseItem(child, `${idx}-${childIdx}`)
      ))
    ) : (
      item.link && item.component && !item.hidden ? renderRoute({route: item, index: idx}) : null
    )
  )

  const parseHiddenItem = (item, idx) => (
    item.children.length > 0 ? (
      item.children.map((child,childIdx)=>(
        parseHiddenItem(child, `${idx}-${childIdx}`)
      ))
    ) : (
      item.link && item.component && item.hidden ? renderRoute({route: item, index: idx}) : null
    )
  )

  const normalRoutes = () => navigation.map((mod, key) => mod.children.length > 0 ? mod.children?.map((item,idx) => parseItem(item, idx)) : parseItem(mod, key))
  const hiddenRoutes = () => navigation.map((mod, key) => mod.children.length > 0 ? mod.children?.map((item,idx) => parseHiddenItem(item, idx)) : parseHiddenItem(mod, key))
  const megaMenuRoutes = () => megaMenu.map((mod, key) => mod.children.map((item,idx) => renderRoute({ route: item, index: idx })))
  
  return (
    <React.Fragment>
      {preloader ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className={themeType}>
          <Switch>
            {normalRoutes()}
            {hiddenRoutes()}
            {megaMenuRoutes()}
            {/* Redirect / route to proper page */}
            {defaultRoute.current ? <Route exact path="/"><Redirect to={defaultRoute.current} /></Route> : null}
            {/* <Route exact path="/"><Redirect to={isAuth ? "/home" : "/login"}  /></Route> */}
            <Route path="/404"><Pages404 /></Route>
            {/* Fallback Route (Default Route) */}
            <Redirect to="/404" />

          </Switch>
        </div>
      )}
      
    </React.Fragment>
  );
}

export default App;
