import { atom, selector } from "recoil";
import { createDataRequest, getInmateLocationListRequest, getAtollListRequest, getIslandListRequest, getArrestTypeListRequest, getRepresentativeRelationListRequest, getComplaintTypeListRequest } from "../../../api/controller/CaseFileController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";
import { Badge } from "reactstrap";

export const guestCaseFileListState = atom({
    key: 'guestCaseFileListState',
    default: [],
})
export const guestCaseFileInmateLocationState = atom({
	key: 'guestCaseFileInmateLocationState',
	default:{
		data:[],
		ovf:false,
	}
})
export const guestCaseFileArrestTypeState = atom({
	key: 'guestCaseFileArrestTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const guestCaseFileRepresentativeRelationState = atom({
	key: 'guestCaseFileRepresentativeRelationState',
	default:{
		data:[],
		ovf:false,
	}
})
export const guestCaseFileComplaintTypeState = atom({
	key: 'guestCaseFileComplaintTypeState',
	default:{
		data:[],
		ovf:false,
	}
})
export const guestCaseFileAtollState = atom({
	key: 'caseFileAtollState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "xl", wizard: true }


export const guestCaseFileFilterState = selector({
    key: 'guestCaseFileFilterState',
    get: ({ get }) => (
        [
            
        ]
    ),
    set: ({ set }, e) => {
        
    }
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [  ],
    view: [ { name: 'Default View' } ],
}

export const guestCaseFileDataStructureState = selector({
    key: 'guestCaseFileDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'އެންޓްރީ ނަންބަރު',
                name: 'EntryNumber',
                field: 'entry_number',
                type: 'text',
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                order: { table: 1 },
                table: { maxWidth: 170, customRender: (value, params) => (
                    <>
                        
                        {params.data["unread_messages"] > 0 ? 
                            <Badge title="You have unread messages!" color='warning' className={"bg-warning font-size-14"} style={{padding: "5px 10px"}}>
                                {params.data["unread_messages"] || 0}
                            </Badge> 
                        : null }
                        &nbsp;
                        {value}
                    </>
                ) },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], 
            },
            { 
                label: 'ކޭސް ނަންބަރު',
                name: 'CaseNumber',
                field: 'case_number',
                type: 'text',
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                order: { table: 0 },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], 
            },
			{ 
                label: 'ބަންދު މީހާގެ ނަން',
                name: 'InmateName',
                field: 'inmate_name',
                type: 'text',
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ބަންދު މީހާގެ އައިޑީ ނަންބަރު',
                name: 'InmateIdNumber',
                field: 'inmate_id_number',
                type: 'text',
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ބަންދު މީހާގެ ޖިންސު',
                name: 'InmateGender',
                field: 'inmate_gender',
                type: 'select',
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'ފިރިހެން', value: 'ފިރިހެން' },
						{ label: 'އަންހެން', value: 'އަންހެން' },
					]
				},
            },
			{ 
                label: 'ބަންދު މީހާގެ އުފަން ތާރީޚް',
                name: 'InmateDateOfBirth',
                type: 'datepicker',
                order: { form: 5 }, 
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ބަންދު މީހާ ހުރި ތަން',
                name: 'InmateLocation',
                field: 'inmate_location',
                type: 'select',
                order: { table: 3, form: 6 }, 
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
				list: get(guestCaseFileInmateLocationState),
				getRequest: getInmateLocationListRequest,
            },
			{ 
                label: 'ބަންދުގެ ބާވަތް',
                name: 'ArrestType',
                field: 'arrest_type',
                type: 'select',
                order: { table: 4, form: 7 }, 
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
				list: get(guestCaseFileArrestTypeState),
				getRequest: getArrestTypeListRequest,
            },
			{ 
                label: 'ހާއްސަ ހާލަތެއް ވާނަމަ އެކަން',
                name: 'SpecialCondition',
                field: 'special_condition',
                type: 'text',
                order: { form: 8 }, 
                section: { id: 1, label: 'އާންމު މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ ނަން',
                name: 'RepresentativeName',
                field: 'representative_name',
                type: 'text',
                order: { table: 0, form: 9 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ އައިޑީ ނަންބަރު',
                name: 'RepresentativeIdNumber',
                field: 'representative_id_number',
                type: 'text',
                order: { form: 10 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ބަންދު މީހާއާ އޮތް ގުޅުން',
                name: 'RepresentativeRelation',
                field: 'representative_relation',
                type: 'select',
                order: { table: 6, form: 8.9 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
				list: get(guestCaseFileRepresentativeRelationState),
				getRequest: getRepresentativeRelationListRequest,
            },
			{ 
                label: 'ގުޅުމުގެ ތަފްސީލު',
                name: 'RelationDetails',
                field: 'relation_details',
                type: 'text',
                order: { form: 12 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ އެޑްރެސް',
                name: 'RepresentativeAddress',
                field: 'representative_address',
                type: 'text',
                order: { form: 13 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv', validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ އަތޮޅު',
                name: 'RepresentativeAtoll',
                field: 'representative_island.atoll',
                type: 'select',
                order: { form: 13.1 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: ['same_as:create.0', { enabled: false }, { enabled: false }, { enabled: false }], view: ['same_as:create.0'], 
                list: get(guestCaseFileAtollState),
                getRequest: getAtollListRequest,
                child: {
                    name: 'RepresentativeIsland',
                    fn: getIslandListRequest
                }
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ ރަށް',
                labelEn: 'Representative Island',
                name: 'RepresentativeIsland',
                field: 'representative_island',
                type: 'select',
                order: { form: 13.2 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv', validations: 'required'  }], update: ['same_as:create.0', { enabled: false }, { enabled: false }, { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތާ ގުޅޭނެ ނަންބަރު',
                name: 'RepresentativePhone',
                field: 'representative_phone',
                type: 'text',
                order: { form: 14 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, validations: 'required'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ހުށަހަޅާ ފަރާތުގެ އީމެއިލް',
                name: 'RepresentativeEmail',
                type: 'email',
                order: { form: 15 }, 
                section: { id: 2, label: 'ހުށައަޅާ ފަރާތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'ޝަކުވާގެ ބާވަތް',
                name: 'ComplaintTypes',
                field: 'complaint_types',
                type: 'multiselect',
                order: { form: 16 }, 
                section: { id: 3, label: 'ޝަކުވާގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
				list: get(guestCaseFileComplaintTypeState),
				getRequest: getComplaintTypeListRequest,
            },
			{ 
                label: 'ޝަކުވާގެ ތަފްސީލު',
                name: 'ComplaintDetails',
                field: 'complaint_details',
                type: 'textarea',
                order: { form: 17 }, 
                section: { id: 3, label: 'ޝަކުވާގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv', attributes: { maxLength: '700', rows: '3' }, validations: 'required' }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'އެޓޭޗްމަންޓްތައް',
                name: 'Attachments',
                field: 'attachments',
                type: 'repeater',
                order: { form: 18 },
                section: { id: 3, label: 'ޝަކުވާގެ މަޢުލޫމާތު' },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: [{ enabled: false }], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'ނަން',
                        name: 'Name',
                        field: 'name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'ފައިލް',
                        name: 'File',
                        field: 'file',
                		type: 'file',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv'  }], update: [{ enabled: false }], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'ރިމާކްސް',
                name: 'Remarks',
                field: 'remarks',
                type: 'text',
                order: { form: 23 }, 
                section: { id: 4, label: 'މަސައްކަތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv', validations:'hidden_if:Status,!ބަލައިނުގަނެވޭ' }], 
            },
			{ 
                label: 'ސްޓޭޓަސް',
                name: 'Status',
                field: 'status',
                type: 'select',
                order: { table: 9, form: 24 }, 
                section: { id: 4, label: 'މަސައްކަތުގެ މަޢުލޫމާތު' },
                create: [{ enabled: false }], update: [{ enabled: false }], view: [{ enabled: true, size: { xs: 12, md: 12 }, classes: 'dv'  }], 
				rootValue: true,
				list: {
					ovf: false,
					data:[
						{ label: 'ޕެންޑިންގް', value: 'ޕެންޑިންގް' },
						{ label: 'ބަލައިގަނެވިއްޖެ', value: 'ބަލައިގަނެވިއްޖެ' },
						{ label: 'ބަލައިނުގަނެވޭ', value: 'ބަލައިނުގަނެވޭ' },
						{ label: 'މަސައްކަތް ކުރިޔަށް ދަނީ', value: 'މަސައްކަތް ކުރިޔަށް ދަނީ' },
						{ label: 'ޖަވާބު އަޕްލޯޑް ކުރެވިއްޖެ', value: 'ޖަވާބު އަޕްލޯޑް ކުރެވިއްޖެ' },
						{ label: 'ޖަވާބު ބަލައި ގަނެވިއްޖެ', value: 'ޖަވާބު ބަލައި ގަނެވިއްޖެ' },
                        { label: 'ކެންސަލް ކުރެވިފައި', value: 'ކެންސަލް ކުރެވިފައި' },
					]
				},
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
