import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'caseFiles';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}
export const findDataRequest =  async (data) => {
    return await http.post(`${group}/find`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getGuestDataListRequest =  async (data) => {
    return await http.post(`${group}/getGuestCaseFiles`, data).then(res => res.data).catch(http.defaultCatch);
}

export const isDisplayedRequest =  async (data) => {
    return await http.post(`${group}/isDisplayed`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const assignCaseRequest =  async (data) => {
    return await http.post(`${group}/assign`, data).then(res => res.data).catch(http.defaultCatch);
}
export const rejectCaseRequest =  async (data) => {
    return await http.post(`${group}/reject`, data).then(res => res.data).catch(http.defaultCatch);
}
export const startWorkDataRequest =  async (data) => {
    return await http.post(`${group}/startWork`, data).then(res => res.data).catch(http.defaultCatch);
}
export const answerReceivedDataRequest =  async (data) => {
    return await http.post(`${group}/answerReceived`, data).then(res => res.data).catch(http.defaultCatch);
}
export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const cancelDataRequest =  async (data) => {
    return await http.post(`${group}/cancel`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCaseLogsRequest =  async (data) => {
    return await http.post(`${group}/logs/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getAtollListRequest =  async (data) => {
	return await http.post(`${group}/getAtollList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getIslandListRequest =  async (data) => {
	return await http.post(`${group}/getIslandList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getInmateLocationListRequest =  async (data) => {
	return await http.post(`${group}/getInmateLocationList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getArrestTypeListRequest =  async (data) => {
	return await http.post(`${group}/getArrestTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getRepresentativeRelationListRequest =  async (data) => {
	return await http.post(`${group}/getRepresentativeRelationList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getComplaintTypeListRequest =  async (data) => {
	return await http.post(`${group}/getComplaintTypeList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getAssignedStaffListRequest =  async (data) => {
	return await http.post(`${group}/getAssignedStaffList`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getCountryListRequest =  async (data) => {
	return await http.post(`${group}/getCountryList`, data).then(res => res.data).catch(http.defaultCatch);
}

export const downloadAnswerLetter = async (data) => {
    const response = await http({url: `${group}/downloadAnswerLetter`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data.DownloadName || 'AnswerLetter.docx'));
}

export const getCaseChatsRequest =  async (data) => {
    return await http.post(`${group}/chats/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const messageCaseChatsRequest =  async (data) => {
    return await http.post(`${group}/chats/message`, data).then(res => res.data).catch(http.defaultCatch);
}

export const markAsReadCaseChatsRequest =  async (data) => {
    return await http.post(`${group}/chats/markAsRead`, data).then(res => res.data).catch(http.defaultCatch);
}

export const exportReport =  async (data) => {
    return await http({url: `${group}/export`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
}
