
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/RoleController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const roleListState = atom({
    key: 'roleListState',
    default: [],
})
export const rolePermissionState = atom({
    key: 'rolePermissionState',
    default: [],
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const roleDataStructureState = selector({
    key: 'roleDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "ނަން",
            name: "Name",
            field: "name",
            type: "text",
            order: { table: 1, form: 1 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "ގާޑް ނަން",
            name: "GuardName",
            field: "guard_name",
            type: "select",
            rootValue: true,
            order: { table: 2, form: 2 },
            table: { editable: true },
            create: [{ enabled: true, size: { xs: 12, md: 6 }, default: "web" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: {
                ovf: false,
                data: [
                    { label: "web", value: "web" }
                ]
            }
        },{
            label: "ހުއްދަތައް",
            name: "Permissions",
            field: "permissions",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 3 },
            create: [{ enabled: true, size: 12, forceEn: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(rolePermissionState),
        }].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})