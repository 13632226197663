
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//i18n
// import { withNamespaces } from 'react-i18next/withNamespaces';
import { updateLoginCoverRequest } from '../../../api/controller/AuthController';
import { DynamicModal } from '../../../custom/components/DynamicModal';
import { HasPermission } from '../../../custom/helpers/HasPermission';

// const formModes = {
//     create: [ { name: 'Default Create', action: changePasswordRequest } ],
// }

// const dataStructure = [
//     { 
//         label: "މިހާރުގެ ޕާސްޥޯޑް",
//         name: "CurrentPassword",
//         type: "password",
//         order: { form: 1 },
//         create: [{ enabled: true, size: 12 }],
//     },
//     { 
//         label: "ޕާސްޥޯޑް",
//         name: "Password",
//         type: "password",
//         order: { form: 2 },
//         create: [{ enabled: true, size: 12 }],
//     },
//     { 
//         label: "ޕާސްޥޯޑް އަލުން ލިޔުއްވާ",
//         name: "Password_confirmation",
//         type: "password",
//         order: { form: 3 },
//         create: [{ enabled: true, size: 12 }],
//     },
// ]

const liFormModes = {
    create: [ { name: 'Default Update', action: updateLoginCoverRequest } ],
}
const loginImageDS = [
    { 
        label: "ކަވާ ފޮޓޯ",
        name: "CoverPhoto",
        type: "file",
        order: { form: 1 },
        create: [{ enabled: true, size: 12 }],
    },
    { 
        label: "މެސެޖް",
        name: "Message",
        type: "text",
        order: { form: 2 },
        create: [{ enabled: true, size: 12 }],
    },
]

const ProfileMenu = ({user, history}) => {

    const [menu, setMenu] = useState(false)
    // const [modalConfig, setModalConfig] = useState({visible: false, size: "sm", wizard: false, header: 'ޕާސްވޯޑް ބަދަލުކުރުން', absoluteHeader:true, type: null, typeKey: null, data: {}}) 
    const [liModalConfig, setLiModalConfig] = useState({visible: false, size: "md", wizard: false, header: 'ލޮގިނަ ޕޭޖް ކަވާ ބަދަލުކުރުން', absoluteHeader:true, type: null, typeKey: null, data: {}}) 

    const toggle = () => {
        setMenu(!menu)
    }

    const logout = () => {
        window.location.href = (process.env.REACT_APP_API_URL + "/auth/logout?token=" + localStorage.getItem('authToken'))
        // const response = await logoutRequest()
        // if(response){
        //     localStorage.removeItem('authToken')
        //     window.location.replace("/");
        // }
    }

    // const toggleModal = (row={}, mode=null, modeKey=0) => {
    //     setModalConfig({ ...modalConfig, visible: !modalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row })
    // }

    const toggleLiModal = (row={}, mode=null, modeKey=0) => {
        setLiModalConfig({ ...liModalConfig, visible: !liModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row })
    }
       
        
    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => toggle()} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user" src={user.avatar} alt="Header Avatar" />&nbsp;
                    <span className="d-none d-xl-inline-block ml-2 mr-1">{user.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-left-user">
                    {/* <>
                        <DropdownItem onClick={(e) => {e.preventDefault();toggleModal({}, "Create")}}>
                            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{' ޕާސްވޯޑް ބަދަލުކުރައްވާ'}
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                    </> */}
                    {HasPermission(":change-login-cover") ? (
                        <>
                            <DropdownItem onClick={(e) => {e.preventDefault();toggleLiModal({}, "Create")}}>
                                <i className="bx bx-image font-size-16 align-middle mr-1"></i>{' ލޮގިން ކަވާ'}
                            </DropdownItem>
                            <div className="dropdown-divider"></div>
                        </>
                    ) : null}

                    <DropdownItem tag="a" onClick={()=>logout()} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{' ލޮގްއައުޓް'}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            {/* <DynamicModal config={modalConfig} toggleModal={toggleModal} submitModes={formModes} ds={dataStructure} /> */}
            {HasPermission(":change-login-cover") ? (
                <DynamicModal config={liModalConfig} toggleModal={toggleLiModal} submitModes={liFormModes} ds={loginImageDS} />
            ) : null}
        </React.Fragment>
    );
}

export default withRouter((ProfileMenu));
