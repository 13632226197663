
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/UserController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const userListState = atom({
    key: 'userListState',
    default: [],
})
export const userRoleState = atom({
  key: 'userRoleState',
  default: {
    data: [],
    ovf: false,
  },
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = [
  {
    label: 'އެކްޓިވް',
    name: 'Active',
      size: 6,
      // offset: 6,
      required: true,
      type: 'select',
      value: { label: "Yes", value: true },
      list: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
  }
]

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userDataStructureState = selector({
    key: 'userDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "ނަން",
            name: "Name",
            field: "name",
            type: "text",
            order: { table: 1, form: 1 },
            table: { dv: true },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, classes: 'dv', validations: 'readOnly' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "ޔޫސާނޭމް",
            name: "Username",
            field: "username",
            type: "text",
            order: { table: 2, form: 2 },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, classes: 'dv', validations: 'readOnly' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "އީމެއިލް",
            name: "Email",
            field: "email",
            type: "email",
            order: { table: 3, form: 3 },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, validations: 'readOnly' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },
          {
            label: "ރޯލްތައް",
            name: "Roles",
            field: "roles",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 5 },
            create: [{ enabled: true, size: { xs: 12, md: 12} }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(userRoleState),
          },{
            label: "އެކްޓިވް",
            name: "Active",
            field: "active",
            type: "switch",
            order: { form: 6 },
            create: [{ enabled: true, size: { xs: 6, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },{
            label: "ލޮކް ކުރެވިފައި؟",
            name: "IsLocked",
            field: "is_locked",
            type: "switch",
            order: { form: 7 },
            create: [{ enabled: true, size: { xs: 6, md: 2 }, default: true }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },
          { 
            label: "ޕާސްވޯޑް",
            name: "Password",
            field: "password",
            type: "text",
            order: { form: 8 },
            create: [{ enabled: true, size: { xs: 12, md: 4 }, default: 'welcome@123', validations: 'readOnly', }],
          },
          { 
              label: 'ޓައިޕް',
              name: 'Type',
              field: 'type',
              type: 'select',
              order: { table: 9, form: 5.5 }, 
              create: [{ enabled: false }], update: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], view: [{ enabled: true, size: { xs: 12, md: 6 }, classes: 'dv' }], 
              rootValue: true,
              list: {
                ovf: false,
                data:[
                  { label: 'އެޑްމިން', value: 'އެޑްމިން' },
                  { label: 'ނޯމަލް', value: 'ނޯމަލް' },
                ]
              },
            },
            { 
              label: "މޮބައިލް",
              name: "Mobile",
              field: "mobile_number",
              type: "text",
              order: { form: 10 },
              create: [{ enabled: false }], update: ['same_as:create.0'], view: [ { enabled: true, size: { xs: 12, md: 4 }, validations: 'readOnly' } ],
            }
        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})