import React from 'react';
import { Col,  Modal, Row } from "reactstrap"
import { Loader } from '../../../custom/components/Loader';


export const LogModal = ({visible, loading, data, logCase, toggle}) => {


    return (
        <Modal size="lg" isOpen={visible} toggle={()=>toggle({})} keyboard={false} backdrop="static">
            <Loader loading={loading} transparent />
            <div className="modal-header">
                <h5 className="modal-title mt-0"><span>އެކްސެސް ލޮގްތައް</span> - {logCase?.case_number}</h5>
                <button type="button" onClick={() => toggle({})} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <React.Fragment>
            <div id="modalBody" className="modal-body" style={{fontSize: 'larger'}}>
                <Row style={{paddingLeft:'20px', paddingRight:'20px'}}>
                    <Col md={3}>
                        <p className="mb-1"><b>ގަޑި</b></p>
                    </Col>
                    <Col md={5}>
                        <p className="mb-1"><b>އެކްޝަން</b></p>
                    </Col>
                    <Col md={4}>
                        <p className="mb-1"><b>ޔޫސާ</b></p>
                    </Col>
                </Row>
                <div style={{maxHeight: "650px", overflowX: 'hidden', overflowY: 'scroll', padding: '10px'}}>
                    {data.map((item, key) => (
                        <Row key={key} className="mb-4 p-2" style={key%2 === 0 ? {backgroundColor: 'rgba(0,0,0,0.08)', borderRadius: '15px'} : {backgroundColor: 'rgba(0,0,0,0.04)', borderRadius: '15px'}}>
                            <Col md={3} style={{borderLeft: '1px solid rgba(0,0,0,0.4)'}}>
                                <p className="mb-1"><i style={{fontWeight:'500', fontFamily: 'Times New Roman'}}>{item.parsed_date}</i></p>
                            </Col>
                            <Col md={5}>
                                <p className="mb-1">{item.activity}</p>
                            </Col>
                            <Col md={4}>
                                <p className="mb-1">{item.by}</p>
                            </Col>
                            <Col md={3} style={{borderLeft: '1px solid rgba(0,0,0,0.4)'}}></Col>
                            <Col md={9}>
                                <p className="mb-1"><b>ތަފްސީލު</b>: {item.description}</p>
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" onClick={() => toggle({})} className="btn btn-secondary waves-effect" data-dismiss="modal">ކްލޯސް</button>
            </div>
            </React.Fragment>
        </Modal>
    )

}