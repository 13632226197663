import { atom } from "recoil";

export const loginPageImageState = atom({
    key: 'loginPageImageState',
    default: {
        image: localStorage.getItem('loginImage') || '/app-images/default.png',
        description: localStorage.getItem('loginDesc') || '',
    }
})

export const tokenRefreshIntervalState = atom({
    key: 'tokenRefreshIntervalState',
    default: 25 // Minutes
})
export const authState = atom({
    key: 'authState',
    default: false
})

export const tokenState = atom({
    key: 'tokenState',
    default: {
        token: null,
        expiry: null,
    }
})

export const passwordResetHashState = atom({
    key: 'passwordResetHashState',
    default: null,
})

export const otpReferenceState = atom({
    key: 'otpReferenceState',
    default: null,
})


export const userState = atom({
    key: 'userState',
    default: {
        id: null,
        name: null,
        email: null,
        username: null,
        avatar: null,
    },
})

export const permissionState = atom({
    key: 'permissionState',
    default: [],
})


